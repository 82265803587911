import React from 'react'
import './Home.css'

const Home = () => (
  <div className="home">
    <header className="header">
      <h1>
        <strong>Chad Edrupt</strong>
      </h1>
      <h2>Software Developer</h2>
      <h3>[React, TypeScript, C#, Swift, FP]</h3>
    </header>
  </div>
)

export default Home
